import { FormHelperText, IconButton } from "@mui/material";
import { default as MUIButton } from "@mui/material/Button";

import ArrowForwardOutlinedIcon from "icon/arrow-forward-icon";
import ArrowBackOutlinedIcon from "icon/arrow-back-icon";

import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import AddIcon from "icon/add-icon";
import EditIcon from "icon/edit-icon";
import DeleteIcon from "icon/delete-icon";
import DownloadIcon from "icon/download-icon";
import UploadIcon from "icon/upload-icon";

const StyledMUIButton = styled(MUIButton)``;

export interface ButtonProperties {
  outlined?: boolean;
  small?: boolean;
  medium?: boolean;
  disabled?: boolean;

  withInstructions?: boolean;

  next?: boolean;
  prev?: boolean;
  add?: boolean;
  edit?: boolean;
  deleteButton?: boolean;
  deleteIcon?: boolean;
  download?: boolean;
  upload?: boolean;
  icon?: ReactNode;
  children?: React.ReactNode;

  [x: string]: any;
}

const Button = ({
  outlined,
  small,
  medium,
  disabled,

  withInstructions = false,

  icon,
  next,
  prev,
  add,
  edit,
  deleteButton,
  deleteIcon,
  download,
  upload,
  children,

  ...props
}: ButtonProperties) => {
  const muiVariant = outlined ? "outlined" : "contained";

  // if (disabled && outlined) {
  //   cn = classes.disabledOutlined;
  // }
  // if (icon) {
  //   cn += ` ${classes.iconButton}`
  // }

  let size: "small" | "medium" | "large" = "large";
  if (medium) {
    size = "large";
  } else if (small) {
    size = "small";
  }

  const iconProps: {
    endIcon?: ReactNode;
    startIcon?: ReactNode;
  } = {};

  if (deleteIcon) {
    return (
      <IconButton disabled={disabled} color={"default"} size={size} {...props}>
        <DeleteIcon />
      </IconButton>
    );
  }

  if (icon) {
    iconProps.endIcon = icon;
  } else if (next) {
    iconProps.endIcon = <ArrowForwardOutlinedIcon />;
  } else if (prev) {
    iconProps.startIcon = <ArrowBackOutlinedIcon />;
  } else if (add) {
    iconProps.endIcon = <AddIcon />;
  } else if (edit) {
    iconProps.endIcon = <EditIcon />;
  } else if (deleteButton) {
    iconProps.endIcon = <DeleteIcon />;
  } else if (download) {
    iconProps.startIcon = <DownloadIcon />;
  } else if (upload) {
    iconProps.startIcon = <UploadIcon />;
  }

  return (
    <>
      <StyledMUIButton
        {...iconProps}
        variant={muiVariant}
        disabled={disabled}
        size={size}
        {...props}
      >
        {children}
      </StyledMUIButton>
      {withInstructions && <FormHelperText>&nbsp;</FormHelperText>}
    </>
  );
};

export default Button;
