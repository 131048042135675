import React from "react";
import { Maybe, User } from "types";

const defaultUser: Maybe<User> = null;

export type UserContextType = {
  user: Maybe<User>;
  //setUserId(newUserId: Maybe<string>): void
  loadUserFromSession(): Promise<void>;
  logout(): Promise<void>;
};

const UserContext = React.createContext({
  user: defaultUser,
  //setUserId: (newUserId: Maybe<string>): void => {},
  loadUserFromSession: async (): Promise<void> => {},
  logout: async (): Promise<void> => {},
} as UserContextType);

export default UserContext;
