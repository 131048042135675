import { User } from "types";
import { get, SuccessResult } from "../base";
import { post } from "./base";

export interface LoginParams {
  username: string;
  password: string;
}

// TODO specify types for these requests
export interface LoginRequestParams {
  login: LoginParams;
  client_id?: string;
}

export const login = async (
  data: LoginRequestParams
): Promise<SuccessResult> => {
  return await post("/auth/login", data);
};

export interface EmailLoginParams {
  token?: string;
}

export const tokenLogin = async (
  params: EmailLoginParams
): Promise<SuccessResult> => {
  return await post("/auth/login/token", params);
};

export const logout = async () => {
  return await post("/auth/logout");
};

export interface ImpersonateStartParams {
  user_id_to_impersonate: string;
}

export const impersonateStart = async (
  params: ImpersonateStartParams
): Promise<SuccessResult> => {
  return await post("/auth/impersonate_start", params);
};

export const impersonateStop = async (): Promise<SuccessResult> => {
  return await post("/auth/impersonate_stop");
};

export const identify = async (): Promise<User> => {
  return await get("/user/get/self");
};
