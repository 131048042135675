import { Submission, Carrier, CoverageType, Product } from "types";
import { Cart } from "./cart";
import { Client } from "./client";
import { DataObject } from "./data-object";
import { EndInsured } from "./end-insured";
import { BillingType, Invoice, InvoiceItem } from "./invoice";
import { PolicyItem, CustomPolicyItem } from "./policy_item";
import { AgencyPartner, AgencyPartnerContact } from "./agency-partner";
import { PremiumFinancePartner, PremiumFinanceQuote } from "./premium-finance";
import { Payment } from "./payment";

export enum PolicyStatus {
  not_billed = "not_billed",
  partially_billed = "partially_billed",
  billed = "billed",
  partially_paid = "partially_paid",
  paid = "paid",
  expired = "expired",
  canceled = "canceled",
  past_due = "past_due",
}

export enum PolicyPaymentPlanOption {
  pay_in_full = "pay_in_full",
  installments = "installments",
  choice = "choice",
}

export enum PolicyPaymentPlanTypeFinancing {
  financed = "financed", //For Premium-Financed installments
  direct = "direct", // For client-provided installment plans
}

export enum PolicyBillingSchedule {
  on_event = "on_event",
  statement = "statement",
}

export enum EndorsementBillingSchedule {
  on_event = "on_event",
  policy_schedule = "policy_schedule",
  statement = "statement",
}

export enum PolicyPaymentPlan {
  pay_in_full = "pay_in_full",
  down_payment_quarterly = "down_payment_quarterly",
  down_payment_monthly = "down_payment_monthly",
  quarterly = "quarterly",
  monthly = "monthly",
}

export enum EndorsementBillingInstallment {
  pay_in_full = "pay_in_full",
  quarterly = "quarterly",
  monthly = "monthly",
}

export enum PolicyEventType {
  new_policy = "new_policy",
  endorsement = "endorsement",
  adjustment = "adjustment",
  cancelation = "cancelation",
}

export type PolicyBillingAmountDate = {
  date: string;
  event_type: string;
  events: Array<PolicyEvent>;
  item_amounts: Array<{
    amount: number;
    item: PolicyItem;
  }>;
};

export type Policy = DataObject & {
  client_id?: string;

  cart_id?: string;
  cart?: Cart;

  insured_id?: string;
  insured?: EndInsured;
  insured_name?: string;

  agency_client_id?: string;
  agency?: Client;
  agency_partner?: AgencyPartner;
  agency_name?: string;
  agency_partner_contact?: AgencyPartnerContact;
  pf_client_id?: string;
  is_pf_enabled?: boolean;

  billing_type?: BillingType;
  agency_bill_pays_gross?: boolean;
  product_id?: string;
  product?: Product;

  submission_id?: string;
  submission?: Submission;

  coverage_type_id?: string;
  coverage_type?: CoverageType;

  carrier_id?: string;
  carrier?: Carrier;

  quote_num?: string;
  binder_num?: string;
  policy_num?: string;
  description?: string;

  transaction_date: string;
  effective_date: string;
  expiration_date: string;
  billing_date: string;

  client_commission_pct?: number;
  agency_partner_commission_pct?: number;
  is_quote?: boolean;
  // commission_pct?: number
  // mga_commission_pct?: number

  policy_mep_pct?: number;
  is_policy_short_rate?: boolean;
  is_policy_auditable?: boolean;
  is_policy_filings?: boolean;

  invoice_items?: Array<InvoiceItem>;
  invoices: Array<Invoice>;

  policy_billing_schedule_type?: PolicyBillingSchedule;
  policy_payment_plan_type?: PolicyPaymentPlan;
  premium_downpayment_amt?: number;
  taxes_downpayment_amt?: number;
  fees_downpayment_amt?: number;

  downpayment_pct?: number;
  downpayment_includes_taxes?: boolean;
  downpayment_includes_fees?: boolean;

  downpayment_billing_schedule_type?: PolicyBillingSchedule;
  endorsement_billing_schedule_type?: EndorsementBillingSchedule;
  endorsement_payment_plan_type?: PolicyPaymentPlan;
  endorsement_downpayment_billing_schedule_type?: EndorsementBillingSchedule;

  policy_events: Array<PolicyEvent>;

  remaining_billing_schedule: Array<PolicyBillingAmountDate>;
  upcoming_invoice_details: PolicyBillingAmountDate;

  policy_items?: Array<PolicyItem>;
  custom_policy_items?: Array<CustomPolicyItem>;
  premium_finance_partner?: PremiumFinancePartner;
  payments?: Array<Payment>;

  // Calculated
  premium_amt?: number;
  agency_commission_amt?: number;
  taxes_amt?: number;
  fees_amt?: number;
  total_payable_amt?: number;
  total_payments_amt?: number;
  total_credits_amt?: number;
  balance_due_amt?: number;
  total_billed_amt?: number;
  next_due_date?: string;
  status?: PolicyStatus;
};

export type PolicyEvent = DataObject & {
  policy_id: string;
  policy_event_type?: PolicyEventType;
  name?: string;
  description?: string;

  policy?: Policy;
  is_quote?: boolean;
  pf_quote?: PremiumFinanceQuote;
  pf_contract_id?: string;
  pf_quote_id?: string;

  effective_date: string;
  expiration_date: string;
  billing_date: string;
  transaction_date: string;
  agency_bill_pays_gross?: boolean;
  client_id: string;

  billing_schedule_type?: EndorsementBillingSchedule;
  payment_plan_type?: PolicyPaymentPlan;

  invoice_items?: Array<InvoiceItem>;

  remaining_billing_schedule: Array<PolicyBillingAmountDate>;
  upcoming_invoice_details: PolicyBillingAmountDate;

  policy_items?: Array<PolicyItem>;
  custom_policy_items?: Array<CustomPolicyItem>;

  // Calculated
  premium_amt?: number;
  agency_commission_amt?: number;
  taxes_amt?: number;
  fees_amt?: number;
};
