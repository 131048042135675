import { DataObject } from "./data-object";

export enum TransferState {
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
}

export enum TransferType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
  REVERSAL = "REVERSAL",
  REVERSE = "REVERSE",
  FEE = "FEE",
  DISPUTE = "DISPUTE",
  SETTLEMENT = "SETTLEMENT",
  TRANSFER = "TRANSFER",
}

export enum TransferSubtype {
  PLATFORM_CREDIT = "PLATFORM_CREDIT",
  MERCHANT_DEBIT = "MERCHANT_DEBIT",
  MERCHANT_CREDIT = "MERCHANT_CREDIT",
  PLATFORM_DEBIT = "PLATFORM_DEBIT",
  API = "API",
  APPLICATION_FEE = "APPLICATION_FEE",
  MERCHANT_CREDIT_ADJUSTMENT = "MERCHANT_CREDIT_ADJUSTMENT",
  MERCHANT_DEBIT_ADJUSTMENT = "MERCHANT_DEBIT_ADJUSTMENT",
  PLACEHOLDER_TEMPORARY = "PLACEHOLDER_TEMPORARY",
  PLATFORM_CREDIT_ADJUSTMENT = "PLATFORM_CREDIT_ADJUSTMENT",
  PLATFORM_FEE = "PLATFORM_FEE",
  SETTLEMENT_MERCHANT = "SETTLEMENT_MERCHANT",
  SETTLEMENT_PARTNER = "SETTLEMENT_PARTNER",
  SETTLEMENT_PLATFORM = "SETTLEMENT_PLATFORM",
  SYSTEM = "SYSTEM",
}

export type Transfer = DataObject & {
  trace_id: string;
  application: string;
  destination: string;
  ready_to_settle_at: string;
  externally_funded: string;
  statement_descriptor: string;
  raw: string;
  merchant: string;
  currency: string;
  amount: number;
  source: string;
  fee: number;
  idempotency_id: string;
  payment_id: string;
  parent_transfer_id?: string;
  message?: string;

  state: TransferState;
  type: TransferType;
  subtype: TransferSubtype;

  //tags	Tags{...}
  //messages	Messages{...}
};
