import { Collapse, List, ListItem, ListItemText } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { styled } from "@mui/material/styles";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";

const StyledListItem = styled(ListItem)(
  ({ theme }) => `
  padding-left: 32px;
  &.nav-item-with-children {
    padding-left: 8px;
  }
  &.nav-item-is-child {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 58px;
    color: ${theme.palette.text.primary}; 
  }
  &.Mui-selected {
    color: ${theme.palette.primary.main}
  }
`
);

export interface NavItemData {
  label: string;
  icon?: ReactNode;
  url?: string;
  items?: Array<NavItemData>;
}

interface NavItemProps {
  item: NavItemData;
  isSubItem?: boolean;
  disableInactiveLinks?: boolean;
}

const isSelected = (url: string, currentPath: string): boolean => {
  return currentPath.startsWith(url);
};

export default function NavItem({
  item,
  isSubItem,
  disableInactiveLinks,
}: NavItemProps) {
  const { label, url, items } = item;
  const hasItems = items ? items.length > 0 : false;

  const router = useRouter();
  const currentPath = router.asPath;
  const [showChildren, setShowChildren] = useState<boolean>(false);

  const selected = url && isSelected(url, currentPath);

  useEffect(() => {
    const checkSelected = () => {
      if (selected) {
        setShowChildren(true);
      } else if (hasItems) {
        (items || []).forEach((item) => {
          if (item.url && isSelected(item.url, currentPath)) {
            setShowChildren(true);
          }
        });
      }
    };
    if (currentPath) {
      checkSelected();
    }
  }, [currentPath, hasItems, items, selected]);

  const classNames = [];
  if (hasItems) {
    classNames.push("nav-item-with-children");
  }
  if (isSubItem) {
    classNames.push("nav-item-is-child");
  }

  // When styling listItem, TS stops liking the componet and href props. Force the typing here.
  const extraProps: { [x: string]: any } = {
    component: "a",
    href: url || "#",
  };

  const disabled = !!(
    disableInactiveLinks &&
    extraProps.href === "#" &&
    (!hasItems || (items || []).filter((item) => !!item.url).length === 0)
  );

  return (
    <>
      <StyledListItem
        disabled={disabled}
        selected={!!selected}
        {...extraProps}
        className={classNames.join(" ")}
        sx={{
          typography: isSubItem ? "body2" : "body1",
        }}
        onClick={() => setShowChildren(!showChildren)}
      >
        {hasItems &&
          (showChildren ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
        <ListItemText>{label}</ListItemText>
      </StyledListItem>
      {hasItems && (
        <Collapse in={showChildren}>
          <List disablePadding>
            {(items || []).map((item) => {
              return (
                <NavItem
                  key={item.label}
                  item={item}
                  isSubItem
                  disableInactiveLinks={disableInactiveLinks}
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}
