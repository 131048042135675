import { Snackbar } from "@mui/material";
import React, { Component } from "react";
import Button from "./Button";

interface Props {}

interface State {
  hasPromiseError: boolean;
  errorMessage: string;
}

class RequestErrorHandler extends Component<Props, State> {
  public state: State = {
    hasPromiseError: false,
    errorMessage: "",
  };

  public componentDidMount() {
    window.onunhandledrejection = (err) => {
      this.setState({
        hasPromiseError: true,
        errorMessage: err?.reason?.errors.join(", \n"),
      });
    };
  }

  public onClose = () => {
    this.setState({ hasPromiseError: false });
  };

  public render() {
    if (this.state.hasPromiseError) {
      return (
        <Snackbar
          open={this.state.hasPromiseError}
          onClose={this.onClose}
          message={this.state.errorMessage || "There was an unexpected error."}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          action={
            <Button small onClick={this.onClose}>
              OK
            </Button>
          }
        />
      );
    }
    return null;
  }
}

export default RequestErrorHandler;
