import { Box } from "@mui/system";
import { GridContainer, GridItem, Body2 } from "element";
import { ReactNode } from "react";
import { styled } from "@mui/material/styles";

import EditIcon from "icon/edit-icon";
import CancelIcon from "@mui/icons-material/Cancel";

import { IconButton } from "@mui/material";
import { colors } from "theme/palette";

const StyledBox = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  margin-left: -${theme.spacing(1)};
  margin-right: -${theme.spacing(1)};
  &:hover {
    background-color: ${colors.primary[50]};    
  }
`
);

interface ListItemProps {
  children?: ReactNode;
  subText?: string;
  onEdit?(): void;
  onDelete?(): void;
}

export default function ListItem({
  children,
  subText,
  onEdit,
  onDelete,
}: ListItemProps) {
  let actions: Array<ReactNode> = [];

  if (onEdit) {
    actions.push(
      <GridItem key="edit">
        <IconButton aria-label="Edit" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </GridItem>
    );
  }

  if (onDelete) {
    actions.push(
      <GridItem key="delete">
        <IconButton aria-label="Delete" onClick={onDelete}>
          <CancelIcon color="error" />
        </IconButton>
      </GridItem>
    );
  }

  return (
    <StyledBox>
      <GridContainer justifyContent="space-between" alignItems="center">
        <GridItem>
          <GridContainer column spacing={0}>
            <GridItem>{children}</GridItem>
            {subText && (
              <GridItem>
                <Body2>{subText}</Body2>
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
        <GridItem>
          <GridContainer>{actions}</GridContainer>
        </GridItem>
      </GridContainer>
    </StyledBox>
  );
}
