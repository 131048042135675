import { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJSType,
  ChartConfiguration,
  ChartDataset,
  ChartType,
  ChartOptions,
} from "chart.js";
//import { default as Cjs } from 'chart.js/auto';
import "chartjs-adapter-date-fns";
import theme from "theme";
import { formatNumber } from "dsl/numbers";
import { Maybe } from "types";
import { Box } from "@mui/system";

import { getDataItemColor } from "./chartColors";

//Cjs.defaults.font = theme.typography.fontSize;

// ChartJSType.defaults.font = {
//   family: "poppins",
//   size: 12
// }
// console.log(ChartJSType.defaults)
// ChartJSType.defaults.elements.point.radius = 1;

const getPieChartOptions = (
  chartType: "doughnut" | "pie",
  datasets: Array<ChartDataset<"doughnut" | "pie">>,
  options: ChartOptions<"doughnut" | "pie">,
  labels?: Array<string>
): ChartConfiguration<"doughnut" | "pie"> => {
  return {
    type: chartType,
    data: {
      labels,
      datasets: datasets.map((d) => ({
        ...d,
        backgroundColor: d.data.map(
          (_value, idx) => getDataItemColor(idx) as string
        ),
      })),
    },
    options: {
      cutout: "85%",
      responsive: true,
      plugins: {
        legend: {
          display: false,
          // onClick: (event, legend, element) => {
          //   if (options.onSelectLegendItem) {
          //     options.onSelectLegendItem(legend.text)
          //   }
          // }
        },
      },
      ...options,
    },
  };
};

const getXYChartOptions = (
  chartType: ChartType,
  datasets: Array<ChartDataset>,
  options: ChartOptions<"bar" | "line">,
  labels?: Array<string>
): ChartConfiguration => {
  return {
    type: chartType,
    data: {
      labels,
      datasets: datasets.map((d, idx) => ({
        ...d,
        cubicInterpolationMode: "default",
        tension: 0,
        backgroundColor: getDataItemColor(idx),
        borderColor: getDataItemColor(idx),
      })),
    },
    options: {
      responsive: true,
      ...options,
      plugins: {
        legend: {
          display: datasets.length > 1,
          position: "bottom",
        },
        ...options.plugins,
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ...(options?.scales?.x || {}),
          ticks: {
            maxRotation: 0,
            autoSkipPadding: 15,
            font: {
              size: 12,
            },
            ...(options?.scales?.x?.ticks || {}),
          },
        },
        y: {
          grid: {
            color: theme.palette.divider,
            borderDash: [5, 5],
          },
          ...(options?.scales?.y || {}),
          ticks: {
            callback: function (value, index, values) {
              return "$" + formatNumber(value as number);
            },
            maxRotation: 0,
            autoSkipPadding: 10,
            font: {
              size: 12,
            },
            ...(options?.scales?.y?.ticks || {}),
          },
        },
      },
    },
  };
};

const getChartOptions = (
  chartType: ChartType,
  datasets: Array<ChartDataset>,
  options: ChartOptions<any>,
  labels?: Array<string>
): ChartConfiguration<any> => {
  let co: ChartConfiguration<any> = {
    type: "bar",
    data: {
      labels,
      datasets,
    },
  };
  if (chartType === "line" || chartType === "bar") {
    co = getXYChartOptions(chartType, datasets, options, labels);
  }
  if (chartType === "doughnut" || chartType === "pie") {
    co = getPieChartOptions(
      chartType,
      datasets as Array<ChartDataset<"doughnut" | "pie">>,
      options,
      labels
    );
  }

  return co;

  // const computedChartData = {
  //   labels,
  //   datasets: datasets.map((ds)=>{
  //     return {
  //       data: ds.data,
  //       backgroundColor: ds.data.map((c)=>getDataItemColor(ds.data.indexOf(c)))
  //     }
  //   }),
  //   options
  // }
};

export interface ChartDataProps {
  datasets: Array<ChartDataset>;
  options?: ChartOptions;
  labels?: Array<string>;
}

export interface ChartProps {
  name?: string;
  chartData: ChartDataProps;
}

interface ChartElementProps extends ChartProps {
  chartType: ChartType;
}

const Chart = ({ name, chartData, chartType }: ChartElementProps) => {
  const [chart, setChart] = useState<Maybe<ChartJSType>>(null);
  const { datasets, options, labels } = chartData;
  const canvasRef = useRef(null);

  useEffect(() => {
    const loadChart = () => {
      if (canvasRef.current && datasets) {
        if (chart) {
          chart.destroy();
        }
        const myChart = new ChartJSType(
          canvasRef.current,
          getChartOptions(chartType, datasets, options || {}, labels)
        );

        setChart(myChart);
      }
    };

    if (canvasRef.current && datasets) {
      loadChart();
    }
  }, [datasets, options, labels, chart, chartType]);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <div
        className="chart-conatiner"
        style={{
          position: "relative",
          width: "100%",
          maxHeight: "300px",
        }}
      >
        <canvas
          ref={canvasRef}
          id={`myChart-${name}`}
          style={{ maxHeight: "300px" }}
        />
      </div>
    </Box>
  );
};

export default Chart;
