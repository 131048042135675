import { default as NextLink } from "next/link";
import { default as MUILink } from "@mui/material/Link";
import GridContainer from "./GridContainer";
import GridItem from "./GridItem";
import DownloadIcon from "icon/download-icon";

interface LinkProperties {
  href?: string;
  newTab?: boolean;
  disabled?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  children?: React.ReactNode;
  download?: boolean;
  [x: string]: any;
}

export default function Link({
  href,
  disabled,
  color = "primary",
  children,
  newTab,
  download,
  ...props
}: LinkProperties) {
  const { onClick } = props;
  const disabledStyles = disabled
    ? {
        cursor: "default",
        textDecoration: "none",
        color: "text.secondary",
      }
    : {};
  if (download) {
    return (
      <Link
        href={href}
        disabled={disabled}
        color={color}
        newTab
        {...props}
        sx={{
          whiteSpace: "nowrap",
          ...(props.sx || {}),
        }}
      >
        <GridContainer
          spacing={0}
          align="center"
          flexWrap="nowrap"
          justifyContent={props.justifyContent}
        >
          <GridItem>
            <DownloadIcon />
          </GridItem>
          <GridItem>{children}</GridItem>
        </GridContainer>
      </Link>
    );
  }

  if (onClick) {
    return (
      <MUILink
        href="#"
        sx={disabledStyles}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {children}
      </MUILink>
    );
  } else {
    const extraProps: {
      target?: string;
      rel?: string;
      [x: string]: any;
    } = { ...props };
    if (newTab) {
      extraProps.target = "_blank";
      extraProps.rel = "noreferrer noopener";
    }
    const anchor = (
      <MUILink sx={disabledStyles} color={color} {...extraProps}>
        {children}
      </MUILink>
    );
    if (href) {
      return (
        <NextLink href={href} passHref>
          {anchor}
        </NextLink>
      );
    } else {
      return anchor;
    }
  }
}
