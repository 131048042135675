import { Box, SxProps } from "@mui/system";
import {
  Table as MUITable,
  TableFooter,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
  Theme,
} from "@mui/material";
import { ReactElement, ReactNode } from "react";
import ContentCard, { CardProps } from "./ContentCard";
import { styled } from "@mui/material/styles";
import { Maybe } from "types";
import { Body2, Checkbox } from "element";

const StyledContentCard = styled(ContentCard)(
  ({ theme }) => `
  padding: 0;
  border: 0;
  & .MuiCardContent-root {
    padding: 0px;
    &:last-child {
      padding: 0px;
    }
  }
`
);

const StyledBox = styled(Box)(
  ({ theme }) => `
  //border-radius: ${theme.shape.borderRadius}px;
  overflow: hidden;
  border-bottom: 1px solid ${theme.palette.divider};
  @media print {
    border-bottom: none;
  }
`
);

export type Order = "asc" | "desc";

export type TableCellProps = {
  sx?: SxProps;
  key?: string;
  colSpan?: number;
  rowSpan?: number;
  value?: Maybe<string | number | ReactElement | ReactNode>;

  // used by the backend to sort, should be equivalent to the SQLAlchemy column name
  sortKey?: string;
};

export type TableRowProps = {
  sx?: SxProps;
  key?: string;
  selectable?: boolean;
  cells: Array<string | number | TableCellProps | null>;
};

export interface TableProps {
  compact?: boolean;
  asCard?: boolean;
  selectable?: boolean;
  allSelectable?: boolean;
  order?: Order;
  orderBy?: Maybe<string>;
  ContentCardProps?: CardProps;
  sx?: SxProps;
  header?: Array<Array<string | TableCellProps | null>>;
  footer?: Array<Array<string | TableCellProps | null>>;
  footerSx?: SxProps;
  headerSx?: SxProps;
  rows: Array<Array<string | number | TableCellProps | null> | TableRowProps>;
  onSelectRow?: (rowKey: any) => void;
  onSelectAllRows?: () => void;
  onClickRow?: (idx: number) => void;
  onSort?: (event: React.MouseEvent<unknown>, sortKey: string) => void;
  selectedRows?: Array<any>;
  noDataContent?: ReactNode;
}

export default function Table({
  asCard,
  selectable,
  allSelectable,
  order,
  orderBy,
  ContentCardProps,
  compact,
  sx,
  header,
  footer,
  rows,
  noDataContent,
  headerSx,
  footerSx,
  onSelectRow,
  onSelectAllRows,
  onClickRow,
  onSort,
  selectedRows,
}: TableProps) {
  let Wrapper: any = StyledBox;
  let wrapperProps = {
    sx: (sx || {}) as { [x: string]: any },
  };

  const createSortHandler =
    (sortKey: string) => (event: React.MouseEvent<unknown>) => {
      if (!onSort) {
        return;
      }

      onSort(event, sortKey);
    };

  if (asCard) {
    Wrapper = StyledContentCard;
    wrapperProps = {
      ...wrapperProps,
      ...(ContentCardProps || {}),
    };
    wrapperProps.sx["overflowX"] = "auto";
    if (ContentCardProps?.title) {
      wrapperProps.sx[
        "& .MuiCardContent-root, & .MuiCardContent-root:last-child"
      ] ||= {};
      wrapperProps.sx[
        "& .MuiCardContent-root, & .MuiCardContent-root:last-child"
      ].paddingTop = "25px";
    }
    if (ContentCardProps?.footer) {
      wrapperProps.sx[
        "& .MuiCardContent-root, & .MuiCardContent-root:last-child"
      ] ||= {};
      wrapperProps.sx[
        "& .MuiCardContent-root, & .MuiCardContent-root:last-child"
      ].paddingBottom = "25px";
    }
  }

  // const selectableRows = useMemo(() => {
  //   return (rows as Array<TableRowProps>)?.filter((r) => r.selectable) || [];
  // }, [rows]);
  const selectableRows = ((rows as Array<TableRowProps>) || [])?.filter(
    (r) => r.selectable
  );

  return (
    <Wrapper {...wrapperProps}>
      <MUITable size={compact ? "small" : "medium"} sx={sx}>
        <TableHead sx={headerSx}>
          {(header || [])
            .filter((v) => v != null)
            .map((headerRow, ridx) => {
              return (
                <TableRow key={`header-${ridx}`}>
                  {selectable && allSelectable && (
                    <TableCell key={"selectAllCol"}>
                      <Checkbox
                        label=""
                        checked={
                          selectedRows?.length &&
                          selectedRows.length === selectableRows.length
                        }
                        onClick={() => onSelectAllRows && onSelectAllRows()}
                      ></Checkbox>
                    </TableCell>
                  )}
                  {selectable && !allSelectable && (
                    <TableCell key={"selectionCol"}></TableCell>
                  )}
                  {(headerRow || [])
                    .filter((v) => v != null)
                    .map((column, idx) => {
                      let key;
                      let label;
                      let sx;
                      let rowSpan, colSpan;
                      if (column && typeof column === "object") {
                        key = column.key;
                        if (!key && typeof column.value === "string") {
                          key = `${column.value}-${idx}`;
                        }
                        label = column.value;
                        sx = column.sx;
                        rowSpan = column.rowSpan;
                        colSpan = column.colSpan;
                      } else {
                        key = column;
                        label = column;
                      }

                      const sortKey =
                        typeof column === "object" && column?.sortKey;
                      const isColumnSorted = !!sortKey && orderBy === sortKey;

                      if (sortKey) {
                        label = (
                          <TableSortLabel
                            active={isColumnSorted}
                            direction={isColumnSorted ? order : "asc"}
                            onClick={createSortHandler(sortKey)}
                          >
                            {label}
                          </TableSortLabel>
                        );
                      }

                      return (
                        <TableCell
                          key={key || idx}
                          sx={sx}
                          rowSpan={rowSpan}
                          colSpan={colSpan}
                          sortDirection={isColumnSorted ? order : false}
                        >
                          {label}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableHead>
        <TableBody>
          {rows
            .filter((v) => v != null)
            .map((row, idx) => {
              const items = Array.isArray(row) ? row : row.cells;
              const selectableRow = Array.isArray(row) ? true : row.selectable;
              let sx: SxProps = (Array.isArray(row) ? {} : row.sx) as SxProps;
              if (onClickRow) {
                sx = {
                  ...sx,
                  ...({
                    "&:hover": {
                      backgroundColor: (theme: Theme) =>
                        theme.palette.secondaryLight.main,
                    },
                    cursor: "pointer",
                  } as SxProps),
                } as SxProps;
              }
              const rowKey = Array.isArray(row) ? idx : row.key || idx;
              return (
                <TableRow
                  key={rowKey}
                  sx={sx}
                  onClick={() => onClickRow && onClickRow(idx)}
                >
                  {selectable && selectableRow && (
                    <TableCell
                      sx={(items[0] as TableCellProps)?.sx || {}}
                      key={`${rowKey || idx}-selection`}
                    >
                      <Checkbox
                        label=""
                        checked={selectedRows?.includes(rowKey)}
                        onClick={() => onSelectRow && onSelectRow(rowKey)}
                      ></Checkbox>
                    </TableCell>
                  )}
                  {selectable && !selectableRow && (
                    <TableCell
                      sx={(items[0] as TableCellProps)?.sx || {}}
                      key={`${rowKey || idx}-selection`}
                    ></TableCell>
                  )}
                  {items
                    .filter((v) => v != null)
                    .map((cell, idx) => {
                      let key;
                      let label;
                      let sx;
                      let rowSpan, colSpan;
                      if (cell && typeof cell === "object") {
                        key = cell.key;
                        if (!key && typeof cell.value === "string") {
                          key = `${cell.value}-${idx}`;
                        }
                        label = cell.value;
                        sx = cell.sx;
                        rowSpan = cell.rowSpan;
                        colSpan = cell.colSpan;
                      } else {
                        key = `cell-${idx}`;
                        label = cell;
                      }

                      return (
                        <TableCell
                          key={key || idx}
                          sx={sx}
                          rowSpan={rowSpan}
                          colSpan={colSpan}
                        >
                          {label}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
          {rows.length === 0 && noDataContent && (
            <TableRow>
              <TableCell
                sx={{ textAlign: "center" }}
                colSpan={
                  (((header || [])[0] || []) && (header || [])[0]?.length) || 1
                }
              >
                <Body2>{noDataContent}</Body2>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter sx={footerSx}>
          {(footer || [])
            .filter((v) => v != null)
            .map((footerRow, ridx) => {
              return (
                <TableRow key={`footer-${ridx}`}>
                  {selectable && <TableCell key={"selectionCol"}></TableCell>}

                  {(footerRow || [])
                    .filter((v) => v != null)
                    .map((column, idx) => {
                      let key;
                      let label;
                      let sx;
                      let rowSpan, colSpan;
                      if (column && typeof column === "object") {
                        key = column.key;
                        if (!key && typeof column.value === "string") {
                          key = column.value;
                        }
                        label = column.value;
                        sx = column.sx;
                        rowSpan = column.rowSpan;
                        colSpan = column.colSpan;
                      } else {
                        key = column;
                        label = column;
                      }

                      return (
                        <TableCell
                          key={key || idx}
                          sx={sx}
                          rowSpan={rowSpan}
                          colSpan={colSpan}
                        >
                          {label}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableFooter>
      </MUITable>
    </Wrapper>
  );
}
