import { TablePagination, TableContainer } from "@mui/material";
import { MouseEvent, ChangeEvent, useCallback, useMemo } from "react";
import { Skeleton } from "@mui/material";
import Table, { Order, TableProps } from "./Table";
import { Filter } from "types/filter";
import { Maybe } from "types";

export interface PaginationTableFilter {
  page: number;
  rowsPerPage: number;
  order?: Order;
  orderBy?: string;
}
export interface PaginationTableProps extends TableProps {
  paginationTableFilter: PaginationTableFilter;
  onPaginationTableFilterChange: (newFilter: PaginationTableFilter) => void;
  count: Maybe<number>;
  loaded: boolean;
}

export function paginationTableFilterToAPIFilter(
  paginationFilter: PaginationTableFilter
): Filter {
  return {
    offset: paginationFilter.rowsPerPage * paginationFilter.page,
    limit: paginationFilter.rowsPerPage,
    order_by: paginationFilter.orderBy
      ? `${paginationFilter.orderBy} ${paginationFilter.order}`
      : undefined,
    include_total: true,
  };
}

export default function PaginationTable({
  paginationTableFilter,
  onPaginationTableFilterChange,
  count,
  header,
  rows,
  loaded,
  ...tableProps
}: PaginationTableProps) {
  const { page, rowsPerPage, order, orderBy } = paginationTableFilter;

  const handleOnPageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      onPaginationTableFilterChange({
        ...paginationTableFilter,
        page: newPage,
      });
    },
    [onPaginationTableFilterChange, paginationTableFilter]
  );

  const handleOnRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onPaginationTableFilterChange({
        ...paginationTableFilter,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      });
    },
    [onPaginationTableFilterChange, paginationTableFilter]
  );

  const onSort = useCallback(
    (event: MouseEvent<unknown>, sortKey: string) => {
      const isAsc = orderBy === sortKey && order === "asc";
      onPaginationTableFilterChange({
        ...paginationTableFilter,
        order: isAsc ? "desc" : "asc",
        orderBy: sortKey,
      });
    },
    [orderBy, order, onPaginationTableFilterChange, paginationTableFilter]
  );

  // Modify rows for 2 reasons:
  // #1 - avoid layout jumps on the last page by inserting empty rows
  // #2 - add loading state to avoid layout jumps
  const newRows = useMemo(() => {
    const headerRow = header?.length && header[0];
    const headerRowColumns = headerRow ? headerRow : [];
    const numberOfHeaderRowColumns = headerRowColumns.length;

    if (!loaded) {
      const newRows = [];
      for (let i = 0; i < rowsPerPage; i++) {
        const newRow = [];
        for (let j = 0; j < numberOfHeaderRowColumns; j++) {
          newRow.push({ key: j, value: <Skeleton variant="text" /> });
        }
        newRows.push(newRow);
      }

      return newRows;
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const numberOfEmptyRows = page > 0 ? rowsPerPage - rows.length : 0;

    // If we have empty rows to show (to avoid layout jump), add them
    if (numberOfEmptyRows > 0) {
      // Fill in with zero width spaces
      const emptyRow = Array(numberOfHeaderRowColumns).fill("\u200B");
      const emptyRows = Array(numberOfEmptyRows).fill(emptyRow);
      return [...rows, ...emptyRows];
    }

    // Otherwise just return the rows unmodified
    return rows;
  }, [rows, loaded, page, rowsPerPage, header]);

  return (
    <TableContainer>
      <Table
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        header={header}
        rows={newRows}
        {...tableProps}
      />
      <TablePagination
        onRowsPerPageChange={handleOnRowsPerPageChange}
        onPageChange={handleOnPageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        page={page}
        count={count == null ? -1 : count} //only set to -1 if count is undefined/null
        sx={{
          display: "flex",
          justifyContent: "right",
          borderBottom: "none",
        }}
      />
    </TableContainer>
  );
}
