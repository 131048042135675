import React, { useState } from "react";
import { Autocomplete } from "@mui/material";
import { FormFieldProps, Maybe } from "types";
import Input from "./Input";
import WithFieldErrors from "element/form/WithFieldErrors";

import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/base";

export interface SelectOption {
  value?: any;
  disabled?: boolean;
  label: string;
}

export interface SelectProps extends FormFieldProps {
  options: Array<SelectOption>;
  multiple?: boolean;
  onChange?: (
    event: React.SyntheticEvent,
    value: Maybe<SelectOption> | Array<SelectOption>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<SelectOption | Array<SelectOption>>
  ) => void;
}

function Select({ options, multiple, postOnChange, ...props }: SelectProps) {
  const {
    onChange,
    name,
    formContext,
    freeSolo,
    disabled,
    getOptionDisabled,
    disableClearable,
    ...otherProps
  } = props;
  const [inputValue, setInputValue] = useState<string>("");

  let handleChange: (
    event: React.SyntheticEvent,
    value: Maybe<SelectOption> | Array<SelectOption>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<SelectOption | Array<SelectOption>>
  ) => void;
  if (onChange) {
    handleChange = onChange;
  } else if (formContext && props.name) {
    handleChange = (
      _event: React.SyntheticEvent<Element, Event>,
      value: Maybe<SelectOption> | Array<SelectOption>
    ) => {
      let newValue: Maybe<string | Array<string>> = null;
      if (multiple && Array.isArray(value)) {
        newValue = (value || []).map((i) => (i ? i.value || i.label : null));
      } else if (!Array.isArray(value)) {
        newValue = value ? value.value || value.label : null;
        setInputValue((value?.label || value?.value || "") as string);
      }
      const newData: { [x: string]: any } = { ...formContext.data };
      newData[props.name as string] = newValue;

      if (freeSolo && props.freeSoloName) {
        newData[props.freeSoloName as string] = null;
      }
      formContext.updateData(newData);
      if (postOnChange) {
        postOnChange(formContext, newData);
      }
    };
  }

  const handleInputChange = (event: any) => {
    if (freeSolo && formContext && props.freeSoloName) {
      const newData: { [x: string]: any } = { ...formContext.data };
      newData[props.name as string] = null;
      newData[props.freeSoloName as string] = event.target.value;
      formContext.updateData(newData);
    }
    setInputValue(event.target.value);
  };

  let controlledValueOption: Maybe<SelectOption> | Array<SelectOption>;
  if (multiple) {
    const controlledValues =
      props.value ||
      (formContext?.data && props.name && formContext.data[props.name]) ||
      [];
    controlledValueOption = controlledValues.map(
      (controlledValue: SelectOption) => {
        return options.find((o) => o.value === controlledValue);
      }
    );
  } else {
    const controlledValue =
      props.value ||
      (formContext?.data && props.name && formContext.data[props.name]) ||
      {};
    controlledValueOption = options.find((o) => o.value === controlledValue);
  }

  const handleInputClear = () => {
    if (formContext && props.name) {
      const newData: { [x: string]: any } = { ...formContext.data };
      newData[props.name as string] = null;
      if (freeSolo && props.freeSoloName) {
        newData[props.freeSoloName as string] = null;
      }
      formContext.updateData(newData);
    }
    setInputValue("");
  };
  let displayInputValue = inputValue;
  if (!multiple && controlledValueOption) {
    displayInputValue =
      (controlledValueOption as SelectOption)?.label ||
      (controlledValueOption as SelectOption)?.value;
  }
  if (freeSolo && props.freeSoloName && !controlledValueOption) {
    displayInputValue =
      (formContext?.data && formContext.data[props.freeSoloName]) ||
      displayInputValue;
  }

  return (
    <Autocomplete<SelectOption | Array<SelectOption>, boolean>
      value={controlledValueOption}
      disabled={disabled}
      autoSelect={!freeSolo}
      inputValue={freeSolo ? displayInputValue : undefined}
      onChange={(event, value, reason, details) => {
        handleChange(event, value as SelectOption, reason, details);
      }}
      getOptionDisabled={getOptionDisabled}
      freeSolo={freeSolo}
      multiple={multiple}
      getOptionLabel={(option) => {
        return (
          (option as SelectOption).label ||
          (option as SelectOption).value ||
          option
        );
      }}
      renderInput={(params) => {
        return (
          <Input
            {...params}
            {...otherProps}
            onChange={freeSolo ? handleInputChange : undefined}
          />
        );
      }}
      componentsProps={
        freeSolo
          ? {
              clearIndicator: {
                onClick: handleInputClear,
              },
            }
          : undefined
      }
      options={options}
      disableClearable={disableClearable}
    />
  );
}

export default WithFieldErrors(Select);
