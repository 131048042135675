import { User, UserType } from "types";
import { GetAllParams, GetParams, SuccessResult } from "../base";
import { post } from "./base";

export interface UserLoginSendParams {
  email: string;
  redirect_url?: string;
}

export const userMagicLinkEmailSend = async (
  params: UserLoginSendParams
): Promise<SuccessResult> => {
  return await post("/magic-link/email/send", params);
};

export interface UserPasswordResetSendParams {
  email: string;
}

export const userPasswordResetSend = async (
  params: UserPasswordResetSendParams
): Promise<SuccessResult> => {
  return await post("/password/reset/send", params);
};

export interface UserPasswordResetUpdateParams {
  token: string;
  password: string;
}

export interface ResetSuccessResult extends SuccessResult {
  user: User;
}

export const userPasswordResetUpdate = async (
  params: UserPasswordResetUpdateParams
): Promise<ResetSuccessResult> => {
  return await post("/password/reset/update", params);
};

export interface UserSelfUpdatePasswordParams {
  old_password: string;
  new_password: string;
}

export const userSelfUpdatePassword = async (
  params: UserSelfUpdatePasswordParams
): Promise<boolean> => {
  return await post("/self/update/password", params);
};

export interface UserGetAllParams extends GetAllParams {
  client_id?: string;
}

export const userGet_all = async (
  params?: UserGetAllParams
): Promise<Array<User>> => {
  return await post("/get_all", params);
};

export interface UserGetParams extends GetParams {}

export const userGet = async (params: UserGetParams): Promise<User> => {
  return await post("/get", params);
};

export interface UserCreateUpdateFields {
  first_name: string;
  last_name: string;
  email?: string;
  password?: string;
}

export interface UserCreateParams {
  obj: UserCreateUpdateFields;
  user_type: UserType;
  client_id?: string;
  insured_id?: string;
  send_email?: boolean;
}

export const userCreate = async (params: UserCreateParams): Promise<User> => {
  return await post("/create", params);
};

export interface UserUpdateParams {
  obj: UserCreateUpdateFields;
  user_id: string;
}

export const userUpdate = async (params: UserUpdateParams): Promise<User> => {
  return await post("/update", params);
};

export interface UserDeleteParams {
  user_id: string;
}

export const userDelete = async (
  params: UserDeleteParams
): Promise<SuccessResult> => {
  return await post("/delete", params);
};

export const userHasAcceptedTos = async (): Promise<boolean> => {
  return await post("/get/accepted-tos");
};

export const userAcceptTos = async (): Promise<SuccessResult> => {
  return await post("/accept-tos");
};
