import { Typography } from "@mui/material";
import { TypographyProps } from "./typography-props";

export default function H4({ children, ...props }: TypographyProps) {
  return (
    <Typography variant="h4" {...props}>
      {children}
    </Typography>
  );
}
