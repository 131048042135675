import React from "react";
import { default as MUIBox } from "@mui/material/Box";

type BoxProps = {
  children?: React.ReactNode;
  [x: string]: any;
};

export default function Box({ children, ...props }: BoxProps) {
  return <MUIBox {...props}>{children}</MUIBox>;
}

// export default MUIBox
