import { Client } from "./client";
import { EndInsured } from "./end-insured";
import { DataObject } from "./data-object";
import { Policy, PolicyEvent } from "./policy";
import { PaymentMethod, Payment, PaymentAllocation } from "./payment";
import { AgencyPartner, AgencyPartnerContact } from "./agency-partner";
import { Credit } from "./credit";
import { StatementInvoice } from "./statement";
import { PolicyItem, PolicyItemInvoiceStatus } from "./policy_item";
import { Carrier } from "./carrier";
import { PremiumFinancePartner } from "./premium-finance";

export enum InvoiceStatus {
  draft = "draft",
  active = "active",
  partially_paid = "partially_paid",
  paid = "paid",
  past_due = "past_due",
  voided = "voided",
}

export enum BillingType {
  direct_bill = "direct_bill",
  agency_bill = "agency_bill",
}

export type InvoiceData = {
  // loan_id?: string
  payment_date?: string;
  // payment_amt?: number
  payment_method?: { [x: string]: any };
  // payment_option?: PaymentOption
  // payment_str?: string
  payment_type?: PaymentMethod;
  notes?: string;
  // transaction_type?: string
};

export type InvoiceItem = DataObject & {
  invoice_id?: string;
  invoice?: Invoice;
  policy_id?: string;
  policy?: Policy;
  policy_event_id?: string;
  policy_event?: PolicyEvent;

  policy_item_id?: string;
  policy_item?: PolicyItem;

  premium_id?: string;
  fee_id?: string;
  tax_id?: string;

  amount: number;
  notes?: string;
  invoice_status?: PolicyItemInvoiceStatus;
};

export type Invoice = DataObject & {
  client_id?: string;
  client?: Client;

  carrier_id?: string;
  carrier?: Carrier;

  insured_id?: string;
  insured?: EndInsured;

  agency_client_id?: string;
  agency_partner?: AgencyPartner;
  agency_partner_contact_id?: string;
  agency_partner_contact?: AgencyPartnerContact;

  pf_client_id?: string;
  premium_finance_partner?: PremiumFinancePartner;
  pf_partner_name?: string;

  cart_id?: string;
  policy_id?: string;
  policy_event_id?: string;
  invoice_num?: string;
  days_past_due?: number;
  is_active?: boolean;
  is_voided?: boolean;

  status?: InvoiceStatus;
  billing_type?: BillingType;
  is_statement_invoice: boolean;
  data: InvoiceData;
  invoice_date: string;
  due_date: string;
  expiration_date: string;

  mga_client_id?: string;

  invoice_items: Array<InvoiceItem>;
  payments?: Array<Payment>;
  payment_allocations?: Array<PaymentAllocation>;
  credits?: Array<Credit>;
  policies?: Array<Policy>;
  policy?: Policy;
  policy_events?: Array<PolicyEvent>;
  policy_event: PolicyEvent;

  statement_invoices?: Array<StatementInvoice>;

  submission_bill_id?: string;
  payments_total_amt?: number;
  invoice_total_amt?: number;
  balance_due_amt?: number;

  disable_reminders?: boolean;

  agency_client_business_name?: string;
  insured_legal_name?: string;
  pf_co_business_name?: string;
  carrier_name?: string;

  is_payables_invoice?: boolean;
  requires_cash_receipt?: boolean;
  is_proforma?: boolean;
};

// For invoiceGet_all and billGet_all calls, we return an InvoiceSummary
// instead of a full invoice. This lets us return less data to the client
// and have more performant getAll calls.
export type InvoiceSummary = Omit<
  Invoice,
  | "payments"
  | "payment_allocations"
  | "credits"
  | "agency_partner_contact"
  | "client"
  | "policy_events"
  | "agency_client"
  | "premium_finance_partner"
  | "insured"
>;
