import { DataObject } from "./data-object";
import { Client } from "./client";
import { PolicyEvent } from "./policy";
import { EndInsured } from "./end-insured";

export type PremiumFinanceSettings = DataObject & {
  pf_client_id: string;
  honor_capital_ff_general_agency_id?: string;
};

export type PremiumFinanceRateScheduleItem = DataObject & {
  pf_compenstaion_id: string;
  min_financed_amt: number;
  max_financed_amt?: number;
  base_interest_rate_pct: number;
  additional_take_rate_pct?: number;
};

export type PremiumFinanceRateSchedule = DataObject & {
  client_id: string;
  pf_client_id: string;
  carrier_id?: string;
  coverage_type_id?: string;
  rate_schedule_items?: Array<PremiumFinanceRateScheduleItem>;
};

export type PremiumFinancePartner = DataObject & {
  client_id: string;
  pf_client_id: string;
  pf_client?: Client;
  min_downpayment_pct: number;
  include_taxes_in_downpayment: boolean;
  include_fees_in_downpayment: boolean;
  honor_capital_agency_id?: string;
  honor_capital_general_agency_id?: string;
  rate_schedule?: PremiumFinanceRateSchedule;
  balance_due_amt?: number;
  past_due_amt?: number;
};

export enum PremiumFinanceQuoteStatus {
  saved = "saved",
  approved = "approved",
  pending_approval = "pending_approval",
  bound = "bound",
  void = "void",
  rejected = "rejected",
  printed = "printed",
}

export type PremiumFinanceQuotePolicyEventAllocation = DataObject & {
  pf_quote_id: string;
  policy_event_id: string;
  funding_amt?: number;
  policy_event?: PolicyEvent;
};

export type PremiumFinanceQuote = DataObject & {
  client_id: string;
  client: Client;
  pf_client_id: string;
  insured_id: string;
  insured: EndInsured;
  policy_event_allocations: PremiumFinanceQuotePolicyEventAllocation[];
  status?: PremiumFinanceQuoteStatus;
  downpayment_pct?: number;
  num_installments?: number;
  actual_interest_rate_pct?: number;
  due_to_pfc_amt?: number;
  doc_stamps?: number;
  finance_charge?: number;
  financed_amt?: number;
  financing_method?: string;
  interest_amt?: number;
  max_interest_rate?: number;
  min_interest_rate?: number;
  min_downpayment_pct_allowed?: number;
  payment_amt?: number;
  interest_rate_pct?: number;
  producer_fee?: number;
  service_charge_amt?: number;
  total_of_payments?: number;
  unpaid_balance_amt?: number;
  additional_info?: string;
  is_approval_required?: boolean;
  errors?: string;
  honor_capital_quote_id?: number;
};

export enum PremiumFinanceDocumentType {
  bundle = "bundle",
  drafts = "drafts",
  receipts = "receipts",
  ach_application = "achapplication",
  finance_coupons = "financecoupons",
  finance_agreement = "financeagreement",
  payment_instructions = "paymentinstructions",
}
