import { PolicyPaymentPlanOption } from "types";
import { DataObject } from "./data-object";
import { BillingType } from "./invoice";
import {
  EndorsementBillingSchedule,
  PolicyPaymentPlan,
  PolicyBillingSchedule,
} from "./policy";
import { PaymentProcessor } from "./payment";

export enum ClientFinixStatus {
  pending = "pending",
  approved = "approved",
  suspended = "suspended",
}

export type ClientData = {
  //reply_to?: string
  accounts_payable_email?: string;
};

export type ClientWireInstructions = DataObject & {
  bank_name: string;
  bank_address: string;
  account_holder_name: string;
  account_holder_address: string;
  swift: string;
  routing_number: string;
  account_number: string;
};

export type ClientCheckInstructions = DataObject & {
  payable_to: string;
  mailing_address: string;
};

export type ClientFeatures = DataObject & {
  is_demo?: boolean;
  accepts_ach?: boolean;
  accepts_cc?: boolean;
  accepts_wire?: boolean;
  accepts_check?: boolean;
  submissions_enabled?: boolean;
  premium_financing_enabled?: boolean;
  payables_enabled?: boolean;
  manual_invoicing?: boolean;
};

export type ClientBillingSettings = DataObject & {
  billing_type: BillingType;
  enabled: boolean;

  billing_schedule_type?: PolicyBillingSchedule;
  payment_plan_type?: PolicyPaymentPlan;

  payment_plan_type_options?: PolicyPaymentPlanOption;
  installment_fee?: number;
  payment_plan_type_financing?: string;

  payment_collection_enabled?: boolean;

  downpayment_billing_schedule_type?: PolicyBillingSchedule;

  endorsement_billing_schedule_type?: EndorsementBillingSchedule;
  endorsement_payment_plan_type?: PolicyPaymentPlan;

  statement_bill_day?: number;
  statement_enabled?: boolean;
  statement_due_in_days?: number;
  due_in_days?: number;

  downpayment_pct?: number;
  downpayment_includes_taxes?: boolean;
  downpayment_includes_fees?: boolean;

  invoice_bill_number_format?: string;
  submission_bill_number_format?: string;
};

export type ClientBranding = DataObject & {
  logo_file_name?: string;
  primary_color?: string;
  secondary_color?: string;
  font_family?: string;
  mui_theme?: any; // additional mui theme overrides
  standalone_checkout?: boolean;
};

export type ClientEmailSettings = DataObject & {
  invoice_reply_to_email?: string;
  invoice_send_from_email?: string;
  accounts_payable_email?: string;
  internal_invoice_email?: string;
  client_sendgrid_api_key?: string;
  disable_reminders?: boolean;
};

export type ClientPaymentProcessorMerchantSettings = DataObject & {
  is_allowed: boolean;
  is_enabled: boolean;
  finix_status: string;
  finix_merchant_id: string;
  finix_identity_id: string;
  processing_rate_card_pct: number;
  processing_rate_card_amt: number;
  processing_rate_ach_amt: number;
  ach_limit_amt: number;
  moov_account_id: string;
  is_moov_allowed: boolean;
  default_ach_processor: PaymentProcessor;
};

export enum ClientType {
  mga = "mga",
  broker = "broker",
  agency = "agency",
  premium_finance_company = "premium_finance_company",
}

export type Client = DataObject & {
  name: string;
  type: ClientType;
  contact_email: string;
  contact_phone: string;
  contact_address1: string;
  contact_address2: string;
  contact_city: string;
  contact_state: string;
  contact_postal_code: string;
  license_number: string;
  license_state: string;
  domain: string;
  features?: ClientFeatures;
  billing_settings?: Array<ClientBillingSettings>;
  branding?: ClientBranding;
  wire_instructions?: ClientWireInstructions;
  check_instructions?: ClientCheckInstructions;
  finix_status?: ClientFinixStatus;
  finix_payment_plan_id?: string;
  payment_processor_merchant_settings?: ClientPaymentProcessorMerchantSettings;
  email_settings?: ClientEmailSettings;
  accounting_settings?: ClientAccountingSettings;
  data: ClientData;
  is_system: boolean;
};

export type ClientSettings = {};

export type ClientCodatSettings = DataObject & {
  company_id: string;
  name: string;
  platform: string;
};

export type ClientAccountingSettings = DataObject & {
  codat_company_id: string;
  gross_written_premium_account_identifier?: string;
  gross_written_premium_account_name?: string;
  agency_commission_account_identifier?: string;
  agency_commission_account_name?: string;
  client_commission_account_identifier?: string;
  client_commission_account_name?: string;
  premium_net_account_identifier?: string;
  premium_net_account_name?: string;
  payment_deposit_account_identifier?: string;
  payment_deposit_account_name?: string;
  sync_type?: string;
};
