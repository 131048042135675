import { Button, GridContainer, GridItem, Input } from "element";
import ArrowForwardIcon from "icon/arrow-forward-icon";
import { useCallback, useState } from "react";
import { Maybe } from "types";

export interface SearchInputProps {
  search?: Maybe<string>;
  onExecuteSearch?: (search: string) => void;
}

export default function SearchInput({
  search,
  onExecuteSearch,
}: SearchInputProps) {
  const [searchText, setSearchText] = useState<string>(search || "");
  const onChangeSearch = useCallback(() => {
    onExecuteSearch && onExecuteSearch(searchText);
  }, [searchText, onExecuteSearch]);

  return (
    <GridContainer alignItems="center">
      <GridItem>
        <Input
          small
          label="Search"
          value={searchText}
          onEnter={onChangeSearch}
          onChange={(event: any) => {
            setSearchText(event.target?.value.toString());
          }}
        />
      </GridItem>
      <GridItem>
        <Button disabled={searchText === search} small onClick={onChangeSearch}>
          <ArrowForwardIcon />
        </Button>
      </GridItem>
    </GridContainer>
  );
}
