import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import WithFieldErrors from "./WithFieldErrors";

import { FormFieldProps } from "types";
import { Input } from ".";
import { InputProps } from "./Input";

export interface DateInputProps extends FormFieldProps {
  // startIcon?: ReactNode
  // endIcon?: ReactNode
  onChange?: (date: Date | null, keyboardInputValue?: string) => void;
  // children?: ReactNode
  // submitOnEnter?: boolean
}

const DateInput = ({
  formContext,
  onChange,
  label,
  views,
  ...props
}: DateInputProps) => {
  let handleChange: (
    date: Date | null,
    keyboardInputValue?: string
  ) => void = () => {};
  if (onChange) {
    handleChange = onChange;
  } else if (formContext && props.name) {
    handleChange = (date: Date | null, keyboardInputValue?: string) => {
      if (date) {
        formContext.updateField(props.name as string, date);
      }
    };
  }

  const controlledValue =
    props.value ||
    (formContext?.data && props.name && formContext.data[props.name]) ||
    null;

  //todo: not getting styled like input
  return (
    <DatePicker
      label={label}
      views={views}
      onChange={handleChange}
      value={controlledValue}
      //error={false}
      renderInput={(params: any) => {
        const inputParams = {
          ...params,
          ...props,
        } as InputProps;
        return <Input {...inputParams} />;
      }}
    />
  );
};

export default WithFieldErrors(DateInput);

//export default withFieldErrors(DateInput));
