import * as _ from "lodash";
import { createTheme } from "@mui/material/styles";
import { lighten, PaletteOptions } from "@mui/material";
import { default as defaultPalette, colors } from "./palette";
import { ClientBranding } from "types";

export const BORDER_RADIUS = 20;
export const INPUT_RADIUS = 4;
export const BUTTON_RADIUS = 8;
export const FONT_FAMILY = "Poppins, sans-serif";

export const buildTheme = (
  buildPalette: (defaultPalette: PaletteOptions) => PaletteOptions
) => {
  const palette = buildPalette(defaultPalette);
  const baseTheme = createTheme({ palette });
  return createTheme({
    palette,
    shape: {
      borderRadius: INPUT_RADIUS,
    },
    typography: {
      fontSize: 14,
      fontFamily: FONT_FAMILY,
      h1: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 400,
      },
      h2: {
        fontSize: "20px",
        lineHeight: "26px",
        fontWeight: 400,
      },
      h3: {
        fontSize: "16px",
        lineHeight: "28px",
        fontWeight: 400,
      },
      overline: {
        color: baseTheme.palette.text.disabled,
        fontSize: "12px",
        lineHeight: "32px",
        fontWeight: 400,
        textTransform: "uppercase",
      },
      body1: {
        fontSize: "14px",
        lineHeight: "20px",
      },
      body2: {
        fontSize: "12px",
        lineHeight: "20px",
        color: baseTheme.palette.text.disabled,
      },
      subtitle1: {
        fontSize: "12px",
        lineHeight: "16px",
        color: baseTheme.palette.text.disabled,
      },
      subtitle2: {
        fontSize: "10px",
        lineHeight: "14px",
        color: baseTheme.palette.text.disabled,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.appBarBackground.main,
            boxShadow: "none",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: baseTheme.palette.divider,
            height: 89,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            padding: 0,
            fontFamily: FONT_FAMILY,
            backgroundColor: baseTheme.palette.background.default,
            "@media print": {
              backgroundColor: "white",
              color: "black",
            },
          },
          ul: {
            paddingLeft: baseTheme.spacing(3),
          },
          ".MuiTextField-root .MuiInputBase-multiline": {
            padding: 0,
            ".MuiInputBase-input": {
              padding: "16.5px 14px",
            },
          },
        },
      },
      // Commented lines below is experimenting with shrink label stylings
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     notchedOutline: {
      //       'legend span': {
      //         display: 'none'
      //       }
      //     }
      //   }
      // },
      // MuiInputLabel: {
      //   styleOverrides: {
      //     root: {
      //       transform: 'translate(14px, 32px) scale(1)'
      //     },
      //     shrink: {
      //       transform: 'translate('+INPUT_RADIUS+'px, -8px) scale(1)',

      //     }
      //   }
      // },
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       padding: baseTheme.spacing(2) + ' 0 0 0',

      //     }
      //   }
      // },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: baseTheme.palette.background.paper,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            paddingBottom: "2px",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            color: baseTheme.palette.text.primary,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          // filled: {
          //   background: baseTheme.palette.primaryLight.main,
          //   color: baseTheme.palette.primary.main
          // }
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            //background: "linear-gradient(98.24deg, rgba(35, 11, 49, 0.8) 7.89%, rgba(23, 12, 61, 0.8) 57.01%, rgba(23, 11, 49, 0.8) 101.27%), #FFFFFF",
            //boxShadow: "unset",
            //border: `1px solid ${baseTheme.palette.divider}`,
            borderRadius: BORDER_RADIUS,
            //color: 'white',
            //textAlign: 'center',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: BORDER_RADIUS,
            padding: BORDER_RADIUS,
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          //maxWidth: "lg",
          //fixed: true
        },
        styleOverrides: {
          root: {
            [baseTheme.breakpoints.up("md")]: {
              padding: "0 20px",
            },
            [baseTheme.breakpoints.up("lg")]: {
              padding: "0 70px",
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            "@media print": {
              marginBottom: "1em",
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: baseTheme.palette.backgroundHeader,
            "& .MuiTableCell-root": {
              fontWeight: 600,
            },
            "@media print": {
              borderBottom: "1px solid " + baseTheme.palette.divider,
              "& .MuiTableCell-root": {
                border: 0,
                background: "white !important",
              },
            },
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            border: 0,
            background: baseTheme.palette.secondaryLight.main,
            "& .MuiTableCell-root": {
              borderBottom: 0,
              color: baseTheme.palette.secondary.contrastText,
              fontWeight: 600,
            },
            "@media print": {
              borderTop: "1px solid " + baseTheme.palette.divider,
              "& .MuiTableCell-root": {
                border: 0,
                background: "white !important",
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          sizeMedium: {
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: 400,
            padding: baseTheme.spacing(2),
            "&:first-of-type": {
              paddingLeft: baseTheme.spacing(2),
            },
            "&:last-child": {
              paddingRight: baseTheme.spacing(2),
            },
            "@media print": {
              WebkitPrintColorAdjust: "exact !important",
              padding: baseTheme.spacing(0.5),
              border: 0,
              backgroundColor: baseTheme.palette.backgroundSubheader,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            padding: "0 8px",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            ".MuiGrid-item": {
              maxWidth: "100%",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: BUTTON_RADIUS,
            paddingLeft: 22,
            paddingRight: 22,
            [baseTheme.breakpoints.down("sm")]: {
              //minWidth: 270
              width: "100%",
              minHeight: "47px",
            },
          },
          outlined: {
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: colors.primary[50],
            },
            "&.Mui-disabled": {
              backgroundColor: baseTheme.palette.background.default,
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {},
          toolbar: {
            paddingLeft: "2px !important",
            paddingRight: "2px !important",
          },
        },
      },
    },
  });
};

export const buildClientTheme = (branding: ClientBranding) => {
  const primaryColor = branding?.primary_color || "#103A49";
  const primaryLightColor = lighten(primaryColor, 0.85);
  const fontFamily = branding?.font_family;

  const theme = buildTheme((defaultPalette: PaletteOptions) => {
    return {
      ...defaultPalette,
      primary: {
        main: primaryColor,
      },
      primaryLight: {
        dark: "#103A49",
        main: primaryLightColor,
        light: "#ebf3ff",
      },
      secondary: branding?.secondary_color
        ? {
            dark: branding?.secondary_color,
            main: branding?.secondary_color,
            light: branding?.secondary_color,
          }
        : defaultPalette.secondary,
      secondaryLight: branding?.secondary_color
        ? {
            main: branding?.secondary_color,
          }
        : defaultPalette.secondaryLight,
      appBarBackground: {
        main: primaryColor,
      },
      backgroundGradient: primaryColor
        ? `linear-gradient(-15deg, ${primaryLightColor} 0%,  ${primaryColor} 80%)`
        : defaultPalette.backgroundGradient,
    };
  });

  if (branding.mui_theme) {
    _.merge(theme, branding.mui_theme);
  }
  if (fontFamily) {
    return _.cloneDeepWith(theme, (value) =>
      value === FONT_FAMILY ? fontFamily : undefined
    );
  }
  return theme;
};

export default buildTheme((defaultPalette) => defaultPalette);
