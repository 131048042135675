import { Client } from "./client";
import { EndInsured } from "./end-insured";
import { DataObject } from "./data-object";
import { Policy } from "./policy";
import { PaymentOption, PaymentMethod } from "./payment";
import { BillingType } from "./invoice";
import { AgencyPartner } from "./agency-partner";
import { PolicyPaymentPlan } from "types";

export enum CartStatus {
  draft = "draft",
  active = "active",
  sent = "sent",
  completed = "completed",
  canceled = "canceled",
}

export type CartData = {
  loan_id?: string;
  payment_date?: Date;
  payment_amt?: number;
  payment_method?: { [x: string]: any };
  payment_option?: PaymentOption;
  payment_str?: string;
  payment_type?: PaymentMethod;
  notes?: string;
  transaction_type?: string;
};

export type Cart = DataObject & {
  status?: CartStatus;
  parent_cart_id?: string;
  client_id?: string;
  client?: Client;

  agency_client_id?: string;
  agency?: AgencyPartner;
  insured_id?: string;
  insured?: EndInsured;

  billing_type?: BillingType;
  payment_plan_type?: PolicyPaymentPlan;

  create_date: string;
  due_date: string;
  expiration_date: string;

  data?: CartData;

  policies?: Array<Policy>;
};
