import { PaymentInstrument } from "./payment-instrument";
import { Cart } from "./cart";
import { Client } from "./client";
import { EndInsured } from "./end-insured";
import { DataObject } from "./data-object";
import { Credit } from "./credit";
import { PremiumFinancePartner } from "./premium-finance";
import { AgencyPartner } from "./agency-partner";
import { Invoice } from "./invoice";

export enum PaymentMethod {
  ach = "ach",
  card = "card",
  check = "check",
  wire = "wire",
}

export enum PaymentProcessor {
  finix = "finix",
  moov = "moov",
}

export interface PaymentMethodProcessors {
  // key is PaymentMethod
  [key: string]: PaymentProcessor;
}

export enum PaymentOption {
  pif = "pif",
  installments = "installments",
}

export enum ManualPaymentInstrumentType {
  check = "check",
  wire = "wire",
  cash = "cash",
  other = "other",
}

export type PaymentPolicyAllocation = {
  policy_id: string;
  amount: number;
};

export type PaymentInvoiceAllocation = {
  invoice_id: string;
  amount?: number;
  policy_allocations?: Array<PaymentPolicyAllocation>;
};

export type FinixTokenizationConstants = {
  environment: string;
  application_id: string;
};

export interface FinixCredentials extends FinixTokenizationConstants {
  // refer to "https://forms.finixpymnts.com/finix.js" Auth
  auth?: any;
}

export type PaymentData = {
  payment_instrument_type: ManualPaymentInstrumentType;
  description?: string;
  payment_instrument_identifier: string;
};

export type PaymentAllocation = DataObject & {
  payment_id: string;
  invoice_id?: string;
  credit_id?: string;
  policy_id?: string;
  amount: number;

  payment?: Payment;
  credit?: Credit;
};

export enum PaymentStatus {
  pending_review = "pending_review",
  created = "created",
  pending = "pending",
  completed = "completed",
  failed = "failed",
  reversed = "reversed",
  queued = "queued",
  canceled = "canceled",
}

export type Payment = DataObject & {
  amount: number;
  cart_id: string;
  cart?: Cart;
  client_id: string;
  client?: Client;
  agency_client_id?: string;
  agency_client?: Client;
  agency_partner?: AgencyPartner;
  pf_client_id?: string;
  pf_client?: Client;
  pf_partner?: PremiumFinancePartner;
  insured_id?: string;
  insured?: EndInsured;
  carrier_id?: string;

  payment_date: string;
  payment_instrument_id: string;
  payment_instrument?: PaymentInstrument;
  currency: string;
  status: PaymentStatus;
  finix_transfer_id: string;
  moov_transfer_id: string;

  manual_payment_type?: ManualPaymentInstrumentType;
  manual_payment_identifier?: string;
  notes?: string;

  bank_transaction_id?: string;

  is_reversal: boolean;
  reversed_payment_id: string;
  reversal_type: string;

  reversals?: Array<Payment>;
  payment_allocations: Array<PaymentAllocation>;
  unallocated_amt?: number;

  initiated_by_client?: boolean;

  invoices?: Array<Invoice>;
};

export type UnmatchedPayment = Payment & {
  source_name?: string;
  destination_name?: string;
};

export type PaymentRefund = {
  payment_id: string;
  refund_amt: number;
};

export enum PaymentReviewRuleType {
  all = "all",
}

export type PaymentReviewRuleParamsAll = {
  rule_type: PaymentReviewRuleType.all;
  review_required: boolean;
};

export type PaymentReviewRule = {
  client_id?: string;
  payor_client_id?: string;
  payor_insured_id?: string;
  rule_type: PaymentReviewRuleType;
  rule_params: PaymentReviewRuleParamsAll; // || other params in the future for new rule types
};
