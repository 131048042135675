import { InputProps } from "element/form/Input";
import React, { ChangeEvent } from "react";
import NumberFormat from "react-number-format";

export default React.forwardRef(function NumberFormatDollar(
  props: InputProps,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        const target = {
          name: props.name as string,
          value: values.value,
        } as EventTarget & (HTMLInputElement | HTMLTextAreaElement);

        onChange &&
          onChange({
            target,
          } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
      }}
      format={"(###) ###-####"}
      isNumericString
    />
  );
});
