import { Client, Maybe } from "types";
import { DataObject } from "./data-object";

export enum UserType {
  admin = "admin",
  client = "client",
  insured = "insured",
}

export enum UserRole {
  admin = "admin",
  legal = "legal",
  banking = "banking",
  user = "user",
}

export enum UserStatus {
  active = "active",
  pending_approval = "pending_approval",
}

export type User = DataObject & {
  first_name: string;
  last_name: string;
  email: string;

  status: Maybe<UserStatus>;

  user_type: UserType;
  client_id: string;
  client?: Client;
  user_roles: Array<UserRole>;
  insured_id?: string;

  is_impersonating?: boolean;

  invited_at?: Date;
};
