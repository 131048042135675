import { GridContainer, GridItem, Overline } from "element";
import { ReactNode } from "react";

export interface DataLabelProps {
  children: ReactNode;
}

export interface DataValueProps {
  children: ReactNode;
}

export interface DataDisplayProps {
  children: ReactNode;
}

export interface DataItemProps {
  label: string | ReactNode;
  children: ReactNode;
}

export function DataLabel({ children }: DataLabelProps) {
  return <Overline>{children}</Overline>;
}

export function DataValue({ children }: DataValueProps) {
  return <>{children}</>;
}

export function DataItem({ label, children }: DataItemProps) {
  return (
    <GridItem xs={12} sm={4} md={3}>
      <DataLabel>{label}</DataLabel>
      <br />
      <DataValue>{children}</DataValue>
    </GridItem>
  );
}

export default function DataDisplay({ children }: DataDisplayProps) {
  return <GridContainer>{children}</GridContainer>;
}
