import { Grid } from "@mui/material";
import { ReactNode } from "react";
import { styled } from "@mui/material/styles";

interface GridItemProps {
  children?: ReactNode;
  [x: string]: any;
}

const StyledGrid = styled(Grid)(
  ({ theme }) => `
    overflow: hidden;
  `
);

export default function GridItem({ children, ...props }: GridItemProps) {
  return (
    <StyledGrid item {...props}>
      {children}
    </StyledGrid>
  );
}
