import { Maybe } from "types";

export const formatWholePercent = (
  wholePercentage: Maybe<number>,
  nullValue?: Maybe<string>
): string => {
  if (wholePercentage == null) {
    return nullValue ? nullValue : "";
  }
  return formatPercent(wholePercentage / 100);
};

export const formatPercent = (percentage: number): string => {
  return Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
  }).format(percentage);
};

export const formatNumber = (number: number): string => {
  return Intl.NumberFormat("en-US").format(number);
};

export const isAboveLimit = (value: number, limit: Maybe<number>): boolean => {
  if (limit === null || limit === undefined) {
    return false;
  }
  return value >= limit;
};

// // https://stackoverflow.com/a/9461657
// function nFormatter(num, digits) {
//   const lookup = [
//     { value: 1, symbol: "" },
//     { value: 1e3, symbol: "k" },
//     { value: 1e6, symbol: "M" },
//     { value: 1e9, symbol: "G" },
//     { value: 1e12, symbol: "T" },
//     { value: 1e15, symbol: "P" },
//     { value: 1e18, symbol: "E" }
//   ];
//   const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
//   var item = lookup.slice().reverse().find(function(item) {
//     return num >= item.value;
//   });
//   return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
// }
