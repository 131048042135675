import { Maybe } from "types";

export type PayablesReportRow = {
  policy_event_label: string;
  policy_id: string;
  policy_effective_date: string;
  policy_transaction_date: string;
  endorsement_id: string;
  endorsement_effective_date: string;
  endorsement_transaction_date: string;
  agency_partner_name: string;
  client_gross_commission_pct: number;
  carrier_net_premium_pct: number;
  agency_partner_commission_pct: number;
  client_net_commission_pct: number;
  amount_invoiced_as_of_date: number;
  total_premium_amt: number;
  agency_partner_commission_amt: number;
  client_net_commission_amt: number;
  net_payable_premium_amt: number;
  taxes_amt: number;
  fees_amt: number;
  amount_collected_as_of_date: number;
  amount_owed_as_of_date: number;
  amount_collected_for_taxes: number;
  amount_collected_for_other: number;
  adjusted_collected_client_net_commission_amt: number;
  adjusted_collected_net_payable_premium_amt: number;
  adjusted_collected_fees_amt: number;
  is_past_due: boolean;
};

export type PayablesReport = {
  client_id: string;
  client_name: string;
  agency_partner_id: Maybe<string>;
  agency_partner_name: Maybe<string>;
  repport_start_date: string;
  report_end_date: string;
  rows: Array<PayablesReportRow>;
  totals: {
    amount_invoiced_as_of_date: number;
    total_premium_amt: number;
    agency_partner_commission_amt: number;
    client_net_commission_amt: number;
    net_payable_premium_amt: number;
    taxes_amt: number;
    fees_amt: number;
    amount_collected_as_of_date: number;
    amount_owed_as_of_date: number;
    amount_collected_for_taxes: number;
    amount_collected_for_other: number;
    adjusted_collected_client_net_commission_amt: number;
    adjusted_collected_net_payable_premium_amt: number;
    adjusted_collected_fees_amt: number;
    is_past_due: number;
  };
};

export const PAYABLES_REPORT_COLUMNS = {
  policy_id: { label: "Policy ID", type: "string" },
  policy_effective_date: { label: "Policy Eff Date", type: "date" },
  policy_transaction_date: { label: "Policy Trans Date", type: "date" },
  policy_event_label: { label: "Policy Event Desc", type: "string" },
  policy_event_effective_date: { label: "Policy Event Eff Date", type: "date" },
  policy_event_transaction_date: {
    label: "Policy Event Trans Date",
    type: "date",
  },
  endorsement_id: { label: "Endorsement / Policy Event ID", type: "string" },
  endorsement_effective_date: { label: "Endorsement Eff Date", type: "date" },
  endorsement_transaction_date: {
    label: "Endorsement Trans Date",
    type: "date",
  },
  agency_partner_name: { label: "Agency Partner", type: "string" },
  client_gross_commission_pct: {
    label: "<Client> Cmsn Gross",
    type: "percent",
  },
  carrier_net_premium_pct: { label: "Carrier Net Prem", type: "percent" },
  agency_partner_commission_pct: {
    label: "Agency Partner Cmsn",
    type: "percent",
  },
  client_net_commission_pct: { label: "<Client> Cmsn Net", type: "percent" },
  amount_invoiced_as_of_date: {
    label: "Amount Invoiced as of <date>",
    type: "dollar",
  },
  total_premium_amt: { label: "Total Prem", type: "dollar" },
  agency_partner_commission_amt: {
    label: "Agency Partner Cmsn",
    type: "dollar",
  },
  client_net_commission_amt: { label: "<Client> Cmsn Net", type: "dollar" },
  net_payable_premium_amt: { label: "Net Prem (Payable)", type: "dollar" },
  taxes_amt: { label: "Taxes", type: "dollar" },
  fees_amt: { label: "Fees", type: "dollar" },
  amount_owed_as_of_date: { label: "Amount Owed as of <date>", type: "dollar" },
  amount_collected_as_of_date: {
    label: "Amount Col. as of <date>",
    type: "dollar",
  },
  amount_collected_for_taxes: {
    label: "Amount Col. Alloc. to Taxes",
    type: "dollar",
  },
  amount_collected_for_other: {
    label: "Amount Col. Alloc. to Non-Taxes",
    type: "dollar",
  },
  adjusted_collected_client_net_commission_amt: {
    label: "Col. Adj: <Client> Cmsn Net",
    type: "dollar",
  },
  adjusted_collected_net_payable_premium_amt: {
    label: "Col. Adj: Net Prem (Payable)",
    type: "dollar",
  },
  adjusted_collected_fees_amt: { label: "Col. Adj: Fee Inc", type: "dollar" },
  is_past_due: { label: "Collections Flag", type: "boolean" },
};
