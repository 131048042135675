import { Button, GridContainer, GridItem, H2, Loading } from "element";
import { useCallback, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Box } from "@mui/system";

import theme from "theme";
import { impersonateStart } from "requests/system/auth";
import { userGet } from "requests/user";
import UserContext from "context/UserContext";
import useParamFromPath from "hooks/useParamFromPath";
import { Maybe, User, UserType } from "types";

export default function ImpersonatingWrapper() {
  const router = useRouter();
  const userContext = useContext(UserContext);

  const { loadUserFromSession } = userContext;
  const impersonate = useParamFromPath("impersonate");
  const query = router.query;

  const navigateToPageWithoutImpersionateParam = useCallback(() => {
    // Generate a query without the impersonation param
    const filteredQuery = Object.fromEntries(
      Object.entries(query).filter(([key]) => key !== "impersonate")
    );

    // Navigate to the current page with the filtered query
    router.push({ pathname: router.pathname, query: filteredQuery });
  }, [router, query]);

  const startImpersonation = useCallback(async () => {
    if (!impersonate) {
      return;
    }

    await impersonateStart({ user_id_to_impersonate: impersonate });

    // Do a complete refresh of user data (now that there's no impersonation)
    // and show the refreshed page (sans query parameter), after the user change
    await loadUserFromSession();

    navigateToPageWithoutImpersionateParam();
  }, [
    loadUserFromSession,
    impersonate,
    navigateToPageWithoutImpersionateParam,
  ]);

  const cancelImpersonation = useCallback(async () => {
    navigateToPageWithoutImpersionateParam();
  }, [navigateToPageWithoutImpersionateParam]);

  const [userToImpersonate, setUserToImpersonate] =
    useState<Maybe<User>>(undefined);

  const fetchUser = useCallback(async () => {
    if (!impersonate) {
      return;
    }

    const userResp = await userGet({ id: impersonate });
    setUserToImpersonate(userResp);
  }, [impersonate]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (!userToImpersonate) {
    return <Loading />;
  }

  return (
    <Box sx={{ padding: theme.spacing(2) }}>
      <GridContainer justifyContent="center" alignItems="center" column={true}>
        <GridItem>
          <H2>
            {userToImpersonate && (
              <>
                Do you want to start impersonation for
                {userToImpersonate.user_type === UserType.insured &&
                  " insured"}{" "}
                user {userToImpersonate.email}
                {userToImpersonate.client &&
                  ` in ${userToImpersonate.client?.name}`}
                ?
              </>
            )}
          </H2>
        </GridItem>
        <GridItem>
          <GridContainer justifyContent="center" alignItems="center">
            <GridItem>
              <Button outlined onClick={startImpersonation}>
                Start impersonation
              </Button>
            </GridItem>
            <GridItem>
              <Button outlined onClick={cancelImpersonation}>
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Box>
  );
}
