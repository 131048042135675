import { Client } from "./client";
import { DataObject } from "./data-object";
import { AgencyPartnerContact } from "./agency-partner";
import { BilledToType } from "./billed-to";
import { Invoice, InvoiceStatus } from "./invoice";

export enum ConsolidatedInvoiceType {
  payables_statement = "payables_statement",
  receivablse_statement = "receivables_statement",
  submission_bill = "submission_bill",
  checkout = "checkout",
}

export type ConsolidatedInvoice = DataObject & {
  client_id?: string;
  client?: Client;
  consolidated_invoice_type: ConsolidatedInvoiceType;
  billed_to_id?: string;
  billed_to_type?: BilledToType;

  agency_partner_contact_id?: string;
  agency_partner_contact?: AgencyPartnerContact;

  is_active?: boolean;
  invoice_num?: string;

  // is_voided?: boolean;
  consolided_invoice_pdf_file_id?: string;

  invoice_date: string;
  due_date: string;
  expiration_date: string;

  disable_reminders?: boolean;

  invoice_total_amt?: number;
  payments_total_amt?: number;
  balance_due_amt?: number;
  days_past_due?: number;
  status?: InvoiceStatus;

  billed_to_name?: string;
  search?: string;
  invoices?: Array<Invoice>;
};
