import { DataObject } from "./data-object";

export enum BankAccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}

export enum PaymentInstrumentType {
  PAYMENT_CARD = "PAYMENT_CARD",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  BANK_WIRE = "BANK_WIRE",
  BANK_CHECK = "BANK_CHECK",
}

export enum BankAccountStatus {
  pending = "pending",
  verified = "verified",
}

export type PaymentInstrument = DataObject & {
  payment_instrument_id: string;
  client_id: string;
  insured_id: string;
  instrument_type: PaymentInstrumentType;
  card_brand: string;
  card_last4: string;
  bank_type: string;
  bank_masked_account_number: string;
  finix_instrument_id: string;
  is_saved: boolean;
  is_disabled: boolean;
  is_default: boolean;
  name: string;
  moov_account_id?: string;
  moov_bank_account_id?: string;
  moov_bank_account_status?: BankAccountStatus;
};

export type PaymentInstrumentSettings = DataObject & {
  client_id?: string;
  insured_id?: string;
  payments_enabled: boolean;
  ephemeral_instruments_allowed: boolean;
  bank_instrument_limit?: number;
  card_instrument_limit?: number;
};
