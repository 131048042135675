import { DataObject } from "./data-object";
import { InvoiceItem } from "./invoice";

export enum PolicyItemCategory {
  premium_gross_written = "premium_gross_written",
  commission_agency = "commission_agency",
  commission_client = "commission_client",
  premium_net = "premium_net",
  premium_payable = "premium_payable",

  tax_payable_to_carrier = "tax_payable_to_carrier",
  tax_payable_to_agency = "tax_payable_to_agency",
  tax_payable_to_other = "tax_payable_to_other",
  tax_retained = "tax_retained",
  fee_payable_to_carrier = "fee_payable_to_carrier",
  fee_payable_to_agency = "fee_payable_to_agency",
  fee_payable_to_other = "fee_payable_to_other",
  fee_retained = "fee_retained",
  custom_policy_item_type = "custom_policy_item_type",
}

export enum CustomPolicyItemCategory {
  premium = "premium",
  tax = "tax",
  fee = "fee",
  other = "other",
}

export enum PayablesCategory {
  carrier = "carrier",
  agency = "agency",
  retained = "retained",
  other = "other",
}

export const CustomPolicyItemGroups = [
  {
    label: "Premiums",
    category: CustomPolicyItemCategory.premium,
  },
  {
    label: "Taxes",
    category: CustomPolicyItemCategory.tax,
  },
  {
    label: "Fees",
    category: CustomPolicyItemCategory.fee,
  },
  {
    label: "Other",
    category: CustomPolicyItemCategory.other,
  },
];

export enum CustomPolicyItemSubCategory {}

export enum PolicyItemInvoiceStatus {
  invoiced_billed = "invoiced_billed",
  not_invoiced = "not_invoiced",
}

export type CustomPolicyItemType = DataObject & {
  client_id: string;
  category: CustomPolicyItemCategory;
  name: string;
  description: string;

  default_amount: number;
  default_description: string;

  sort: number;

  slug: string;
  accounting_account_identifier: string;
  accounting_account_name: string;
  item_subcategory: CustomPolicyItemSubCategory;
  default_invoice_status: PolicyItemInvoiceStatus;

  is_surplus_item: boolean;
  payables_cateogry?: PayablesCategory;
};

export type PolicyItem = DataObject & {
  category: PolicyItemCategory;
  custom_policy_item_type_id?: string;
  policy_id?: string;
  policy_event_id?: string;
  amount: number;
  description: string;
  custom_policy_item_type?: CustomPolicyItemType;
  invoice_status?: PolicyItemInvoiceStatus;
  invoice_items?: Array<InvoiceItem>;
  pf_amt?: number;
};

export type CustomPolicyItem = DataObject & {
  custom_policy_item_type_id: string;
  policy_id?: string;
  policy_event_id?: string;
  amount: number;
  description: string;
  custom_policy_item_type?: CustomPolicyItemType;
  invoice_status?: PolicyItemInvoiceStatus;

  invoice_items?: Array<InvoiceItem>;
};
