import React from "react";
import { FieldError } from "element";
import { FormContext } from "types";

export type WithFieldErrorsProps = {
  formContext?: FormContext;
  name?: string;
  [x: string]: any;
};

const WithFieldErrors = <P extends object>(
  FieldComponent: React.ComponentType<P>
) => {
  return class WithFieldErrorsComponent extends React.Component<WithFieldErrorsProps> {
    render() {
      let { hasError } = this.props;
      const { formContext, name, index } = this.props;
      if (formContext && name) {
        const { serverErrors, errors, validationErrors } = formContext;
        const fieldErrorMessages = [
          ...((errors.fields[name] && errors.fields[name].messages) || []),
          ...((validationErrors.fields[name] &&
            validationErrors.fields[name].messages) ||
            []),
          // server errors may require lookup by name and index
          ...((isNaN(index)
            ? serverErrors.fields[name] && serverErrors.fields[name].messages
            : serverErrors.fields[name] &&
              serverErrors.fields[name].fields[index] &&
              serverErrors.fields[name].fields[index].messages) || []),
        ];
        hasError = fieldErrorMessages.length > 0;
      }
      return (
        <>
          <FieldComponent {...(this.props as P)} hasError={hasError} />
          {formContext && name && (
            <FieldError name={name} formContext={formContext} index={index} />
          )}
        </>
      );
    }
  };
};

export default WithFieldErrors;
