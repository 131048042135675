import {
  Carrier,
  Client,
  ClientBillingSettings,
  ClientBranding,
  ClientCheckInstructions,
  ClientData,
  ClientEmailSettings,
  ClientFeatures,
  ClientWireInstructions,
} from "types";
import { GetAllParams, GetParams } from "../base";
import { post } from "./base";

export const clientGetAllCarriers = async (): Promise<Array<Carrier>> => {
  return await post("/get_all_carriers");
};

export interface ClientGetParams extends GetParams {}

export const clientGet = async (params: ClientGetParams): Promise<Client> => {
  return await post("/get", params);
};

export interface ClientGetAllParams extends GetAllParams {}

export const clientGet_all = async (
  params?: ClientGetAllParams
): Promise<Array<Client>> => {
  return await post("/get_all", params);
};

export interface ClientCreateParams {
  name: string;
  contact_email?: string;
  contact_phone?: string;
  contact_address1?: string;
  contact_address2?: string;
  contact_city?: string;
  contact_state?: string;
  contact_postal_code?: string;
  license_number?: string;
  license_state?: string;
  domain: string;
  data?: ClientData;
}

export const clientCreate = async (
  params?: ClientCreateParams
): Promise<Client> => {
  return await post("/create", params);
};

export interface ClientUpdateParams {
  update: ClientCreateParams;
  id: string;
}

export const clientUpdate = async (
  params?: ClientUpdateParams
): Promise<number> => {
  return await post("/update", params);
};

export interface ClientSet_featuresParams {
  client_id: string;
  features: ClientFeatures;
}

export const clientSet_features = async (
  params?: ClientSet_featuresParams
): Promise<ClientFeatures> => {
  return await post("/set_features", params);
};

export interface ClientSet_billing_settingsParams {
  client_id: string;
  billing_settings: ClientBillingSettings;
}

export const clientSet_billing_settings = async (
  params?: ClientSet_billing_settingsParams
): Promise<ClientBillingSettings> => {
  return await post("/set_billing_settings", params);
};

export interface ClientSet_email_settingsParams {
  client_id: string;
  email_settings: ClientEmailSettings;
}

export const clientSet_email_settings = async (
  params?: ClientSet_email_settingsParams
): Promise<ClientEmailSettings> => {
  return await post("/set_email_settings", params);
};

export interface ClientGet_email_settingsParams {
  client_id: string;
}

export const clientGet_email_settings = async (
  params?: ClientGet_email_settingsParams
): Promise<ClientEmailSettings> => {
  return await post("/get_email_settings", params);
};

export interface ClientSet_brandingParams {
  client_id: string;
  branding: ClientBranding;
}

export const clientSet_branding = async (
  params?: ClientSet_brandingParams
): Promise<ClientBranding> => {
  return await post("/set_branding", params);
};

export interface ClientSet_wire_instructionsParams {
  client_id: string;
  wire_instructions: ClientWireInstructions;
}

export const clientSet_wire_instructions = async (
  params?: ClientSet_wire_instructionsParams
): Promise<ClientWireInstructions> => {
  return await post("/set_wire_instructions", params);
};

export interface ClientSet_check_instructionsParams {
  client_id: string;
  check_instructions: ClientCheckInstructions;
}

export const clientSet_check_instructions = async (
  params?: ClientSet_check_instructionsParams
): Promise<ClientCheckInstructions> => {
  return await post("/set_check_instructions", params);
};

export interface ClientUserInviteParams {
  user_id: string;
}

export const clientUserInvite = async (
  params: ClientUserInviteParams
): Promise<boolean> => {
  return await post("/invite_user", params);
};
