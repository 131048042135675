import { post as basePost, get as baseGet } from "../base";

export const post = async (
  path: string,
  data?: object,
  headers: object = {}
): Promise<any> => {
  return basePost(`/system${path}`, data, headers);
};

export const get = async (path: string, headers: object = {}): Promise<any> => {
  return baseGet(`/system${path}`, headers);
};
