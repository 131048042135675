import { Typography } from "@mui/material";
import { TypographyProps } from "./typography-props";

export default function Body2({ children, ...props }: TypographyProps) {
  return (
    <Typography variant="body2" {...props}>
      {children}
    </Typography>
  );
}
