import AddIcon from "icon/add-icon";
import { Card, CardActionArea, CardContent, IconButton } from "@mui/material";
import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Subtitle } from "./typography";

interface ListProps {
  children: ReactNode;
  title?: string;
  onAddItem?(): void;
}

const StyledCardActionArea = styled(CardActionArea)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
`
);

export default function List({ children, title, onAddItem }: ListProps) {
  const hasActions = !!onAddItem;
  return (
    <Card sx={{ borderRadius: 0 }}>
      <CardContent>
        {title && <Subtitle>{title}</Subtitle>}
        {children}
      </CardContent>
      {hasActions && (
        <StyledCardActionArea>
          {!!onAddItem && (
            <IconButton aria-label="Add" onClick={onAddItem}>
              <AddIcon color="success" />
            </IconButton>
          )}
        </StyledCardActionArea>
      )}
    </Card>
  );
}
