import React, { ReactNode } from "react";

import { Drawer, List } from "@mui/material";

import { styled } from "@mui/material/styles";

import { Box } from "@mui/system";
import { BORDER_RADIUS } from "theme";

import NavItem, { NavItemData } from "./NavItem";

const StyledBox = styled(Box)(
  ({ theme }) => `
  position: relative;
  box-shadow: 0px 4px 26px -4px rgba(0, 0, 0, 0.18);
  border: 1px solid ${theme.palette.primaryLight.light};
  padding: ${theme.spacing(4)}px 0;
  display: none;
  overflow: hidden;
  background: ${theme.palette.background.paper};
  border-radius: 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0;
  ${theme.breakpoints.up("lg")} {
    display: block
  },
`
);

// sx={{
//   height: 'auto',
// }}

interface ClientMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  items: Array<NavItemData | ReactNode>;
  disableInactiveLinks?: boolean;
}

export default function ClientMenu({
  items,
  open,
  setOpen,
  disableInactiveLinks,
}: ClientMenuProps) {
  // TODO: extract menuItems / List into elements to pre-style and use here (and in other menus)

  const menuItems = (
    <List disablePadding>
      {items.map((navItem) => {
        if (React.isValidElement(navItem)) {
          return navItem;
        } else {
          return (
            <NavItem
              disableInactiveLinks={disableInactiveLinks}
              key={(navItem as NavItemData).label}
              item={navItem as NavItemData}
            />
          );
        }
      })}
    </List>
  );
  //   <ListItem selected button component="a" href={routes.dashboardPath()}>
  //     <ListItemText>Main</ListItemText>
  //   </ListItem>
  //   <ListItem button onClick={()=>setViewsOpen(!viewsOpen)}>
  //     <ListItemIcon>{
  //       viewsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
  //     }</ListItemIcon>
  //     <ListItemText>Views</ListItemText>
  //   </ListItem>
  //   <Collapse in={viewsOpen}>
  //     <List disablePadding>
  //       <ListItem button component="a" href={'#'}>
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Accounts</ListItemText>
  //       </ListItem>
  //       <ListItem button component="a" href={client && routes.clientCartPath(client)}>
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Policies</ListItemText>
  //       </ListItem>
  //       <ListItem button component="a">
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Finance Contracts</ListItemText>
  //       </ListItem>
  //       <ListItem button component="a">
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Partners</ListItemText>
  //       </ListItem>
  //       <ListItem button component="a">
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Payments</ListItemText>
  //       </ListItem>
  //       <ListItem button component="a">
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Payables</ListItemText>
  //       </ListItem>
  //     </List>
  //   </Collapse>

  //   <ListItem button onClick={()=>setActionsOpen(!actionsOpen)}>
  //     <ListItemIcon>{
  //       actionsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
  //     }</ListItemIcon>
  //     <ListItemText>Actions</ListItemText>
  //   </ListItem>
  //   <Collapse in={actionsOpen}>
  //     <List disablePadding>
  //       <ListItem button component="a" href={client && routes.clientCartPath(client, {create: true})}>
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Create Checkout Link</ListItemText>
  //       </ListItem>
  //       <ListItem button component="a" href={'#'}>
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Issue Refund</ListItemText>
  //       </ListItem>
  //       <ListItem button component="a" href={'#'}>
  //         <ListItemIcon></ListItemIcon>
  //         <ListItemText>Schedule/Pay Payable</ListItemText>
  //       </ListItem>
  //     </List>
  //   </Collapse>

  //   <ListItem button component="a" href={'#'}>
  //     <ListItemText>Reports</ListItemText>
  //   </ListItem>

  //   <ListItem button component="a" href={clientPath({client, settings: true})}>
  //     <ListItemText>Settings</ListItemText>
  //   </ListItem>

  //   {/* TODO determine selected criteria */}
  //   { user?.user_type === UserType.admin && <>
  //     <Divider />
  //     <ListItem button component="a" href={userPath()}>
  //       <ListItemText>Users</ListItemText>
  //     </ListItem>
  //   </>}
  // </List>

  return (
    <>
      <Drawer
        sx={{ display: { lg: "none" } }}
        open={open}
        anchor="left"
        onClose={() => setOpen(false)}
      >
        {menuItems}
      </Drawer>
      <StyledBox>{menuItems}</StyledBox>
    </>
  );
}
